@import 'sass/styles';

.find {
  margin: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  align-self: stretch;
  padding: 0 100px;
  background: #fbf9f7;

  @media (max-width: $minDesktopWidth) {
    padding: 0 30px;
    margin: 50px 0;
  }

  @media (max-width: $minMobileWidth) {
    padding: 0 10px;
  }

  a {
    @extend %textLink;

    @media (max-width: $minDesktopWidth) {
      padding: 10px auto;
    }
  }

  form {
    max-width: 872px;
    margin: 0;
  }
}

.column {
  width: 100%;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;

  @media (max-width: $minMobileWidth) {
    padding: 20px 0px;
    gap: 20px;
  }

  + .column {
    gap: 20px;
    padding: 0 0 40px;
  }
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  a {
    padding: 8px 16px;
    background: $white;
  }

  @media (max-width: 700px) {
    margin: 0 auto;
    width: 329px;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
  }
}
