@import 'sass/styles';

.form {
  width: 100%;
  height: 52px;
  position: relative;
  border: none;
  margin: 0;
  padding: 0;
  background: $white;
  margin: 0 0 50px;

  @media (max-width: $maxMobileWidth) {
    height: 42px;
  }

  > div {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 30px;
    height: 100%;
    padding: 0px 10px;
    border-radius: 6px;
  }

  input {
    @extend %typographySmall;
    margin-right: 30px;
  }

  [data-type='mag'] {
    width: 20px;
    height: 20px;
  }

  .clear {
    position: absolute;
    top: 0px;
    right: 10px;
    width: 52px;
    height: 52px;
    padding: 18px;
    background: transparent;
    border: transparent;
    cursor: pointer;

    svg {
      width: 11px;
      height: 11px;
    }

    @media (max-width: $maxMobileWidth) {
      top: -6px;
      right: 0px;
    }
  }
}
